











































































import { Component, Prop, Vue } from "vue-property-decorator";
import { readCreatorsByManager, readManager } from "@/store/manager/getters";
import { api } from "@/api";

@Component
export default class AccountsManagedByManager extends Vue {
  @Prop() public readonly counts: any[];
  public accounts = [];

  get manager() {
    return readManager(this.$store);
  }

  public async mounted() {
    const managerId = parseInt(this.$route.params.id, 0);
    if (managerId) {
      const response = await api.listAccountsByManager(managerId);
      if (response) {
        this.accounts = response.data;
      }
    }
  }
}
